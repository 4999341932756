import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Card from "./dep/stage/Card";
import getImages from "../lib/getImages";
import Blad from "./../images/blad.jpg";
import FancyHeading from "./FancyHeading";
import ScrollBackButton from "./ScrollBackButton";
//import Logos from "./Logos";

const Kalendarium = styled.article`
  margin: 0.5rem 1rem 1rem 0.5rem;
  padding-bottom: 1rem;
`;

const Events = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 1.4rem auto;
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-template-rows: auto;
  grid-gap: 10px;
`;

// Få Inforutan att spänna över de celler som är kvar på raden
const Inforuta = styled.div`
  display: grid;
  justify-content: space-around;

  grid-column: auto / span ${(props) => props.span};

  text-align: center;
  padding: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid #ccc;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
  background-image: url(${Blad});
  background-repeat: no-repeat;
  background-size: 15%;

  .titel {
    font-weight: 600;
    margin: 0.3rem 0;
  }
`;

function getColumns() {
  let colc;
  if (window.innerWidth < 559) {
    colc = 1;
  } else if (window.innerWidth < 841) {
    colc = 2;
  } else if (window.innerWidth < 1111) {
    colc = 3;
  } else {
    colc = 4;
  }
  return colc;
}

const Grid = ({ kalender }) => {
  const antal = kalender.length;
  let span;

  kalender.sort((a, b) => {
    /*
    let da = new Date(a.datum),
      db = new Date(b.datum);
      */
    let da = new Date(a.datum),
      db = new Date(b.datum);
    return da - db;
  });

  const [colCount, setcolCount] = useState(getColumns());

  window.onresize = function () {
    if (getColumns() !== colCount) {
      setcolCount(getColumns());
    }
  };

  /* <CLEANUP> Runs on unmpunt and works! */
  const [, setState] = useState({});
  useEffect(() => {
    myFunction();
    return () => {
      setState({}); // This worked for me
    };
  }, []);
  const myFunction = () => {
    setState({
      name: "John",
      surname: "Doe",
    });
  };
  /* </CLEANUP> */

  // console.log("antal kulumner: ", colCount);
  // console.log("antal celler: ", Math.ceil(antal / colCount) * colCount);
  // console.log("antal evenemang: ", antal);

  const rest = Math.ceil(antal / colCount) * colCount - antal;

  span = rest ? rest : colCount;

  // console.log("span: ", span)

  return (
    <Kalendarium>
      <FancyHeading
        cont="Kultur på Krogbacken"
        more=""
      />
      <Events>
        {kalender.map((obj, index) => (
          <Card
            key={obj.id}
            id={obj.id}
            img={getImages[obj.img + ".jpg"]}
            evenemang={obj.namn}
            datum={obj.datum}
          />
        ))}

        <Inforuta span={span}>

          <div>
            <br />
            <div className="titel">KULTUR PÅ KROGBACKEN 2025</div><br />
            SOMMARENS PROGRAM PRESENTERAS HÄR PÖ OM PÖ!<br /><br />
            {/* arrangeras av  {" "}<br />*/}

            <nobr>av föreningen Kultur på Krogbacken</nobr><br />
            och
            <nobr> Oxelö Krog</nobr>
          </div>

          {/* <Logos /> */}

          <div>
            {span > 1 && (
              <div>
                Scenen byggdes 2021 med stöd av{" "}
                <nobr>Leader Sörmlandskusten</nobr> och{" "}
                <nobr>Oxelösunds Kommun</nobr>.
              </div>
            )}
          </div>

        </Inforuta>

      </Events>
      <ScrollBackButton />
    </Kalendarium>
  );
};

export default Grid;
