import styled from "styled-components";

//import Logo_JIN from "./../images/Logotyp_JIN.png";
//import Logo_Tonkonst from "./../images/Logo_Tonkonst2.png";
import Logos_sponsorer from "./../images/Loggor2025.png";
//import Logo_Oxelosund from "./../images/logotyp_Oxelosund.png";
//import Logo_Kulturradet from "./../images/logo_kulturradet.png";

const LogoHolder = styled.aside`
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr;

  @media screen and (min-width: 800px) {
    grid-gap: 2rem;
  }
  @media screen and (min-width: 900px) {
    grid-gap: 3rem;
  }

  padding: 0 1rem;
`;


const Sponsorer = styled.div`
  padding: 1rem 5%;

  @media screen and (min-width: 650px) {
    padding: 1rem 10%;
  }

  @media screen and (min-width: 750px) {
    padding: 1rem 15%;
  }

  @media screen and (min-width: 900px) {
    padding: 1rem 20%;
  }

  img {
    width: 100%;
  }
`;

const Bidragsgivare = styled.div`
  padding: 1rem 0;
 
  img {
    width: 100%;
    margin: 0.35rem 0;
  }
`;

const Logos = () => {
  return (
    <>
      <LogoHolder>
        {/* <LogoJIN src={Logo_JIN} /> */}
        <Sponsorer>
          <img src={Logos_sponsorer} alt="Sörmlands Sparbank, ICA, Boda Borg, SSAB, Svedlindhs och Oxelö Hamn" />
        </Sponsorer>
        {/* 
               <Bidragsgivare>
              <img src={Logo_Oxelosund} alt="Logo Oxelösunds Kommun" />
              <img src={Logo_Kulturradet} alt="Logo Kulturrådet" />
            </Bidragsgivare>         
            */}

        {/* <LogoTon src={Logo_Tonkonst} /> */}
      </LogoHolder>
    </>
  );
}

export default Logos;