import styled from 'styled-components';

import Navigation from "./../components/Navigation";
import SponsorLoggor from "./../images/Loggor2025.png";
import KulturLogga from "./../images/Krogbacken_logo.png";

const Main = styled.main`
    margin: 0 auto;
    max-width: 1100px;
`;

const Article = styled.article`
    h1, h2 {
        color: #825c26;
    }

    ul {
        margin-top: 0;
    }

    footer {
        text-align: center;
    }
`;

const Header = styled.header`
    margin-top: 1.5rem;
    h1 {
        font-size: 3rem;
        text-align: center;
        line-height: 2.8rem;
    }
`;

const BreadText = styled.div`
    margin: 1rem;

    p { 
        margin-top: 0;
    }

    @media screen and (min-width: 600px) {
        margin-left: 10%;
        margin-right: 10%;
    }

    @media screen and (min-width: 800px) {
        
        column-count: 2;
        column-span: all;
        column-gap: 2rem;
    }
`;

const SponsorPaket = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 950px) {
        flex-direction: row;
        align-items: stretch;
        justify-content: space-around;
    }

    section {
        border: solid 3px rgba(130,92,38,0.6);
        border-radius: 12px;
        padding: 1rem 3rem 0.5rem 3rem;
        display: flex;
        justify-content: center;
        line-height: 1.4rem;
        margin: 1rem 0;
        line-height: 2rem;

        h2 {
            text-align: left;
            margin: 0.5rem 0;
        }

        ul {
            
        }
    }
`;

const Logos = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 400px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 500px) {
        flex-direction: column; 
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        /* img {
            width: 120px;
            display: block;
            margin-bottom: 1rem;

            @media screen and (min-width: 420px) {
                width: 140px;
            }
        }*/
    } 
`;


const SponsorBild = styled.img`
    width: 102px;
    /* height: 58px; */

    @media screen and (min-width: 500px) {
        width: 110px;
    }
`;

const KulturbackenLogo = styled.img`
    width: 150px;
    /* height: 58px; */

    @media screen and (min-width: 500px) {
        width: 200px;
    }
`;



const Loggor = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 0 3rem 0;

    img {
        width: 100%;
    }

    @media screen and (min-width: 800px) {
        width: 70%;
    }
    @media screen and (min-width: 950px) {
        width: 60%;
    }
`;

const Sponsorer = () => {

    return (
        <>
            <Main>
                <Navigation page="sponsorer" />
                <Article>
                    <Header><h1>Sponsorer för Musik på Krogbacken</h1></Header>
                    <Loggor>
                        <SponsorBild src={SponsorLoggor} />
                    </Loggor>
                </Article>
                <Article>
                    <Header><h1>Bli sponsor!</h1></Header>
                    <BreadText>
                        <p>
                            Genom att bli sponsor får ditt företag goda möjligheter att synas och bidrar samtidigt till att utveckla Oxelösund till en levande kulturstad.
                            Vårt mål är att etablera Oxelösund på kartan som en musik-ort.
                        </p>
                        <p>
                            För sommaren 2025 är åtta kvällskonserter inplanerade plus vår traditionella Bellmandag.
                            Till varje konsert finns ett programblad med information om den aktuella konserten med presentation av sponsorer med logotyp eller namn.
                        </p>
                        {/* <p>                    
                            Det finns även möjlighet att bli huvudsponsor till något av våra evenemang. 
                            Då finns möjlighet för din organisation att exponeras lite extra i samband med det specifika evenemanget. 
                        </p> */}
                        <p>
                            Affischer med hela konsertprogrammet kommer att sättas upp i Oxelösund och Nyköping samt orter runt om.
                            Affischering kommer även att ske på väl valda platser i andra städer i Sörmland.
                            På affischerna presenteras sponsorerna med namn eller logotyp.
                        </p>
                        <p>
                            På Oxelö Krogs hemsida och i Sociala medier för Oxelö Krog kommer sponsorernas namn eller logotyp synas tillsammans med konsertprogrammet.
                        </p>
                    </BreadText>

                    <SponsorPaket>
                        {/* <section>
                            <div>
                                <h2>Sponsorpaket Primo</h2>
                                Pris: 6000 kr<br />
                                Som sponsor får du:
                                <ul>
                                    <li>Logotyp i programblad, på affischer och hemsida</li>
                                    <li>Marknadsföring via våra sociala medier</li>
                                    <li>20 fribiljetter till valfria konserter (värde 3000 kr)</li>
                                    <li>10% rabatterat pris vid köp av fler biljetter</li>
                                    <li>Reserverade VIP-platser nära scenen</li>
                                </ul>
                            </div>
                        </section> */}
                        <section>
                            <div>
                                <h2>SPONSORPAKETFÖRSLAG</h2>
                                Pris: 5000 kr<br />
                                Som sponsor får du:
                                <ul>
                                    <li>Logotyp i programblad, på affischer och hemsida</li>
                                    <li>Marknadsföring via våra sociala medier</li>
                                    <li>8 fribiljetter till valfria konserter (värde 2000 kr)</li>
                                    <li>10% rabatterat pris vid köp av fler biljetter</li>
                                </ul>
                            </div>
                        </section>
                    </SponsorPaket>

                    <footer>
                        <p>MUSIK PÅ KROGBACKEN arrangeras av:</p>
                        <Logos>
                            <div>
                                föreningen<br />Kultur på Krogbacken
                            </div>

                            <div>
                                <KulturbackenLogo src={KulturLogga} />
                            </div>
                        </Logos>
                    </footer>

                </Article>
            </Main>
        </>
    )
}

export default Sponsorer;